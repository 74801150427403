.button {
  width: 100%;
  background: #ea1d2c;
  color: #fff;
  cursor: pointer;
  border: none;
  padding: 0 20px;
  border-radius: 4px;
  margin: 3px 0;
  height: 50px;
  font-weight: 500;
  transition: 100ms;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  font-size: 1rem;
  line-height: 1em;
  flex-grow: 1;
  position: relative;
  top: 1px;
  text-align: center;
  font-weight: 500;
}
