.simple-form-input {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 4px 0;
  margin: 5px 0 5px;
  z-index: 0;
  /* min-height: 73px; */
}

.simple-form-input__field[disabled] {
  opacity: 0.4;
}

.simple-form-input__error {
  font-size: 0.75rem;
  margin: 4px 0 0 0;
  color: #b42b2b;
}

.simple-form-input--invalid .simple-form-input__field {
  border-color: #b42b2b;
}

.simple-form-input--invalid .field__border {
  background: #ef5753;
}

.simple-form-input__field {
  color: #3e3e3e;
  background: transparent;
  font-weight: 500;
  outline: none;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  position: relative;
  padding: 13px 20px;
  height: auto;
  z-index: 1;
  width: 100%;
  font-size: 1rem;
  line-height: 20px;
}

.simple-form-input__field:focus + .label__name {
  transform: translateY(-23px);
  font-size: 0.875rem;
  background: #fff;
  z-index: 1;
  padding: 5px;
}

.simple-form-input__field:focus + .label__name + .field__border {
  width: 100%;
}

.simple-form-input__field:-webkit-autofill {
  box-shadow: 0 30px 0 0 $input-background-color inset;
}

.simple-form-input__label {
  background: transparent;
  display: flex;
  flex-direction: column;
  color: #a6a6a6;
  position: relative;
  /* padding: 0; */
}

.simple-form-input__label .label__name {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 20px;
  margin: auto;
  display: flex;
  align-items: center;
  transform: translateY(0);
  transition: 200ms;
  font-size: 1rem;
  left: 14px;
}

.simple-form-input
  .simple-form-input__label.simple-form-input__label--has-value
  .label__name,
.simple-form-input .simple-form-input__field:focus + .label__name {
  transform: translateY(-23px);
  font-size: 0.875rem;
  background: #fff;
  z-index: 1;
  padding: 5px;
}

.simple-form-input__label--cvv {
  background: transparent url(../../assets/icons/CardBack.svg) center no-repeat;
  background-position-x: calc(100% - 16px);
}

.simple-form-input__label--card-number {
  background: transparent url(../../assets/icons/Lock.svg) center no-repeat;
  background-position-x: calc(100% - 16px);
}
