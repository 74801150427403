.card-form__input {
  margin-top: 4em;
}

.card-form__input {
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  padding: 13px 20px;
  height: auto;
  line-height: 20px;
  color: #3e3e3e;
  background: transparent;
  font-weight: 500;
  outline: none;
}

.card-form__smaller-fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-form__smaller-fields .simple-form-input {
  width: 48%;
}

.card-form__button--loading {
  background: #f37a83;
  color: #fff;
  cursor: initial;
}

.payment-method-form__preview {
  margin: 24px 0;
}