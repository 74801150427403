.checkout-header {
  padding: 24px 0;
  box-shadow: inset 0px -1px 0px #DCDCDC;
  background: #fff;
  width: 100%;
  z-index: 2600;
}
.checkout-header__prev-icon {
  display: none !important;
}
.checkout-header__back-button {
  cursor: pointer;
}
@media (max-width: 999px) {
  .checkout-header__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .checkout-header__logo-img {
    max-height: 40px;
    width: auto;
  }
  .checkout-header__prev {
    display: none;
  }
  .checkout-header__prev-icon {
    display: flex !important;
    padding: 8px;
  }
  .checkout-header__prev-icon svg {
    max-height: 32px;
  }
}