.card {
	background: #FFFFFF;
	border: 1px solid #F2F2F2;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 16px;
	width: 100%;
	@media (max-width: 999px) {
		padding: 16px 8px
	}
}
