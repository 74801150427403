* {
  box-sizing: border-box;
}

.flex-grid {
  display: flex;
  margin: 0 -8px;
}
.flex-grid--wrap {
  flex-wrap: wrap;
  max-width: 1302px;
  margin: 0 auto;
}
.flex-grid--direction-row {
  flex-direction: row;
}
.flex-grid--direction-row-reverse {
  flex-direction: row-reverse;
}
.flex-grid--direction-column {
  flex-direction: column;
}
.flex-grid--direction-column-reverse {
  flex-direction: column-reverse;
}
.flex-grid--justify-flex-start {
  justify-content: flex-start;
}
.flex-grid--justify-flex-end {
  justify-content: flex-end;
}
.flex-grid--justify-center {
  justify-content: center;
}
.flex-grid--justify-space-between {
  justify-content: space-between;
}
.flex-grid--justify-space-around {
  justify-content: space-around;
}
.flex-grid--align-flex-start {
  align-items: flex-start;
}
.flex-grid--align-flex-end {
  align-items: flex-end;
}
.flex-grid--align-center {
  align-items: center;
}
.flex-grid--align-baseline {
  align-items: baseline;
}
.flex-grid--align-stretch {
  align-items: stretch;
}
.flex-grid__item {
  flex: 0 0 auto;
  min-width: 0;
  padding: 0 8px;
}
.flex-grid__item--grow {
  flex-grow: 1;
}
.flex-grid__item--shrink {
  flex-shrink: 1;
}
.flex-grid__item--flex {
  display: flex;
}
.flex-grid__item--align-flex-start {
  align-self: flex-start;
}
.flex-grid__item--align-flex-end {
  align-self: flex-end;
}
.flex-grid__item--align-center {
  align-self: center;
}
.flex-grid__item--align-baseline {
  align-self: baseline;
}
.flex-grid__item--align-stretch {
  align-self: stretch;
}
@media screen and (min-width: 0) {
  .flex-grid__item--xs-1 {
    width: 8.3333333333%;
  }
  .flex-grid__item--xs-2 {
    width: 16.6666666667%;
  }
  .flex-grid__item--xs-3 {
    width: 25%;
  }
  .flex-grid__item--xs-4 {
    width: 33.3333333333%;
  }
  .flex-grid__item--xs-5 {
    width: 41.6666666667%;
  }
  .flex-grid__item--xs-6 {
    width: 50%;
  }
  .flex-grid__item--xs-7 {
    width: 58.3333333333%;
  }
  .flex-grid__item--xs-8 {
    width: 66.6666666667%;
  }
  .flex-grid__item--xs-9 {
    width: 75%;
  }
  .flex-grid__item--xs-10 {
    width: 83.3333333333%;
  }
  .flex-grid__item--xs-11 {
    width: 91.6666666667%;
  }
  .flex-grid__item--xs-12 {
    width: 100%;
  }
}
@media screen and (min-width: 576px) {
  .flex-grid__item--sm-1 {
    width: 8.3333333333%;
  }
  .flex-grid__item--sm-2 {
    width: 16.6666666667%;
  }
  .flex-grid__item--sm-3 {
    width: 25%;
  }
  .flex-grid__item--sm-4 {
    width: 33.3333333333%;
  }
  .flex-grid__item--sm-5 {
    width: 41.6666666667%;
  }
  .flex-grid__item--sm-6 {
    width: 50%;
  }
  .flex-grid__item--sm-7 {
    width: 58.3333333333%;
  }
  .flex-grid__item--sm-8 {
    width: 66.6666666667%;
  }
  .flex-grid__item--sm-9 {
    width: 75%;
  }
  .flex-grid__item--sm-10 {
    width: 83.3333333333%;
  }
  .flex-grid__item--sm-11 {
    width: 91.6666666667%;
  }
  .flex-grid__item--sm-12 {
    width: 100%;
  }
}
@media screen and (min-width: 999px) {
  .flex-grid__item--md-1 {
    width: 8.3333333333%;
  }
  .flex-grid__item--md-2 {
    width: 16.6666666667%;
  }
  .flex-grid__item--md-3 {
    width: 25%;
  }
  .flex-grid__item--md-4 {
    width: 33.3333333333%;
  }
  .flex-grid__item--md-5 {
    width: 41.6666666667%;
  }
  .flex-grid__item--md-6 {
    width: 50%;
  }
  .flex-grid__item--md-7 {
    width: 58.3333333333%;
  }
  .flex-grid__item--md-8 {
    width: 66.6666666667%;
  }
  .flex-grid__item--md-9 {
    width: 75%;
  }
  .flex-grid__item--md-10 {
    width: 83.3333333333%;
  }
  .flex-grid__item--md-11 {
    width: 91.6666666667%;
  }
  .flex-grid__item--md-12 {
    width: 100%;
  }
}
@media screen and (min-width: 1300px) {
  .flex-grid__item--lg-1 {
    width: 8.3333333333%;
  }
  .flex-grid__item--lg-2 {
    width: 16.6666666667%;
  }
  .flex-grid__item--lg-3 {
    width: 25%;
  }
  .flex-grid__item--lg-4 {
    width: 33.3333333333%;
  }
  .flex-grid__item--lg-5 {
    width: 41.6666666667%;
  }
  .flex-grid__item--lg-6 {
    width: 50%;
  }
  .flex-grid__item--lg-7 {
    width: 58.3333333333%;
  }
  .flex-grid__item--lg-8 {
    width: 66.6666666667%;
  }
  .flex-grid__item--lg-9 {
    width: 75%;
  }
  .flex-grid__item--lg-10 {
    width: 83.3333333333%;
  }
  .flex-grid__item--lg-11 {
    width: 91.6666666667%;
  }
  .flex-grid__item--lg-12 {
    width: 100%;
  }
}