.snackbar {
    color: #fff;
    padding: 1em;
    position: relative;
    transition: height 600ms cubic-bezier(0.215, 0.61, 0.355, 1) 0ms;
    transition-property: height;
    transition-duration: 600ms;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-delay: 0ms;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
	border-radius: 6px;
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.snackbar__close {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
}
.snackbar__message {
    font-size: 14px;
    line-height: 150%;
    word-wrap: break-word;
    margin: 0;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
}
.snackbar__icon {
	margin-right: 8px;
}
