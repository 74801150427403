.container {
  margin: 0 auto;
}

.background--grey {
  background: rgb(247, 247, 247);
  min-height: 100vh;
}

.background--info {
  background-color: #2E6788 !important;
}

.background--danger {
  background-color: #EA1D2C !important;
}

.background--warning {
  background-color: #E7A74E !important;
}

.background--success {
  background-color: #50A773 !important;
}

@media (min-width: 992px) {
  .container {
    max-width: 1302px !important;
  }
}
